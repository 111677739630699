import React, { Component } from 'react'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import ReactJson from 'react-json-view';
import dayjs from '../../helpers/dayjs';
import { TableFooter, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import FileDownload from 'js-file-download';

import { pageStyle } from '../styles';
import { API_RTU_DEBUG_LIST, API_RTU_DEBUG_DELETE } from '../../constants/api';
import loggedUserServerFetch from '../../services/loggedUserServerFetch';
import { GREENLABS_GREEN } from '../../constants/ui';
import '../../styles/swal2.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
class RtuDebugDialog extends Component {
    constructor (props) {
        super(props);

        this.cols = [
            {id: 'id', field: 'id', title: 'id', sorting: false, },
            {id: 'created_at', field: 'created_at', title: 'Fecha y hora', sorting: false, render: (element) => element.created_at && dayjs(element.created_at).format('DD/MM/YYYY HH:mm')},
        ]

        const timer = setInterval ( () => {
            this.tableRef.current && this.tableRef.current.onQueryChange();
        }, 5000);

        this.state = { 
            dateFrom: '',
            dateTo: '',
            debugInfo: null,
            debugInfoId: null,
            items: [],
            timer: timer,            
        }

        this.tableRef = React.createRef();
    }

    componentWillUnmount = () => {
        if (this.state.timer) clearInterval(this.state.timer);
    }

    componentDidMount = () => {
        this._fetch_first_load();
    }

    updateField = (field, value) => {
        let newState = {...this.state};
        newState[field] = value;
        this.setState(newState, this.doSearch);
    }

    doSearch = () => {
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    _fetch_first_load = () => {
        let q = {sort: -1};
        debugger;        
        let url = API_RTU_DEBUG_LIST.replace('{$mac}', this.props.rtu.mac);

        loggedUserServerFetch (url, 'get', {}, {}, q).then ( (response) => {            
            this.setState ({...this.state, items: response.data })
        })
    }

    _fetch_newer_entries = () => {

    }

    _fetch_older_entries = () => {

    }

    fetchElements = (query) => {
        let q = {};

        let url = API_RTU_DEBUG_LIST.replace('{$mac}', this.props.rtu.mac);

        return new Promise ((resolve, reject) => {
            loggedUserServerFetch (url, 'get', {}, {}, q).then ( (response) => {
                debugger;
                const result = {
                    data: response.data?.items||[],
                    page: (response.data?.pagination?.page -1)||0,
                    totalCount: response.data?.pagination?.total_items||0,
                };
                resolve(result);
    
            }).catch ( (error) => {
                console.error(error);
                toast.error('Ocurrió un error comunicándose con el servidor');
                reject (error);
            })
        })

        /*
        
        if (query?.search !== null) q['search'] = query.search;
        if (query?.orderBy !== null && query?.orderBy !== undefined) {
            q['sort_field'] = query.orderBy?.field;
            q['sort_order'] = query.orderDirection;
        }
        q['page'] = query?.page + 1;
        q['page_size'] = query?.pageSize;

        if (this.state.dateFrom) q['from'] = this.state.dateFrom;
        if (this.state.dateTo) q['from'] = this.state.dateTo;

        let url = API_RTU_DEBUG_LIST.replace('{$id}', this.props.rtu.id);

        return new Promise ((resolve,reject) => {
            loggedUserServerFetch (url, 'get', {}, {}, q).then ( (response) => {
                const result = {
                    data: response.data?.items||[],
                    page: (response.data?.pagination?.page -1)||0,
                    totalCount: response.data?.pagination?.total_items||0,
                };
                resolve(result);
    
            }).catch ( (error) => {
                console.error(error);
                toast.error('Ocurrió un error comunicándose con el servidor');
                reject (error);
            })
        })*/
    }

    displayInformation = (debugItem)  => {
        this.setState( {...this.state, debugInfoId: debugItem.id, debugInfo: debugItem.data});
    }

    deleteDebugInformation = (debugInfo) => {
        Swal.fire({
            text: `¿Desea eliminar permanentemente el registro ${debugInfo.id}? Esta operación no se puede deshacer`,
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText:'Cancelar',
            icon: 'question',
            customClass: {
                container: 'always-on-top'
            }
        }).then( (result) => {
            if (! result.value ) return;

            const url = API_RTU_DEBUG_DELETE.replace('{$id}',this.props.rtu.id).replace('{$debugid}', debugInfo.id);
            
            loggedUserServerFetch ( url, 'delete').then( (result) => {

                if (debugInfo.id === this.state.debugInfoId) this.setState({...this.state, debugInfo: null, debugInfoId: null,})
                this.tableRef.current && this.tableRef.current.onQueryChange();
                
            }).catch( (err) => {
                console.error('Error al eliminar el evento de debug' , debugInfo, err);
                toast.error('Ocurrio un error al eliminar el evento');
                
            }).then( () => {
                
            });
        });
    }

    downloadDebugInformation = (debugInfo) => {
        FileDownload(JSON.stringify(debugInfo.data), `DEBUG-${debugInfo?.id}-${debugInfo?.rtu?.mac}.txt`);
    }

    render = () => {
        const { classes } = this.props;
        if (! this.props.open) return null;
        
        let title = `Diagnóstico de la RTU ${this.props?.rtu?.mac||''}`;
        if (this.props?.rtu?.friendly_name) title += ` - ${this.props.rtu.friendly_name}`;

        return (
            <Dialog fullScreen open={this.props.open} onClose={this.props.onClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar} style={{backgroundColor: '#4b9451'}}>
                    <Toolbar>
                        <Typography 
                            variant="h6" 
                            className={classes.title} 
                            style={{color: 'white', flex: 1}}
                        >
                            {title}
                        </Typography>
                        <IconButton 
                            color="inherit" 
                            onClick={this.props.onClose}
                            aria-label="close" 
                            title="Cerrar" 
                            edge="end"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Grid 
                    container 
                    style={{paddingTop: 75}} 
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                >
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={1}>
                            <Grid key='debugLeftPanel' item xs={12} sm={6}>
                                <Paper elevation={0}>
                                    <Grid container spacing={2} style={{paddingLeft: '17px'}}>
                                        <Grid item xs={5}>
                                            <TextField                                            
                                                fullWidth
                                                id="dateFrom"
                                                label="Desde"
                                                type="date"
                                                InputLabelProps={{shrink: true,}}
                                                value={this.state.dateFrom}
                                                onChange={ (event) => this.updateField('dateFrom', event.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                fullWidth
                                                id="dateTo"
                                                label="Hasta"
                                                type="date"
                                                InputLabelProps={{shrink: true,}}
                                                value={this.state.dateTo}
                                                onChange={ (event) => this.updateField('dateTo', event.target.value)}
                                            />
                                            </Grid>
                                        <Grid item xs={2} style={{textAlign:"right"}}>
                                            <IconButton 
                                                color="inherit" 
                                                onClick={this.doSearch}
                                                aria-label="search" 
                                                title="Buscar"
                                                style={{paddingRight: '17px'}}
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Id</TableCell>
                                                    <TableCell>Fecha y hora</TableCell>
                                                    <TableCell align="right">Acciones</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.items.map( e => (
                                                    <TableRow>
                                                        <TableCell>{e._id}</TableCell>
                                                        <TableCell>{dayjs(e._createdAt).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                                                        <TableCell align='right'>...botones...</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TableCell colSpan={3} align='right'>
                                                        Mas
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                            <Grid key='debugRightPanel' item xs={12} sm={6}>
                                <Paper elevation={1}>
                                    {this.state.debugInfoId !== null && (typeof this.state.debugInfo === 'object') && <ReactJson
                                        src = { this.state.debugInfo }
                                        name = { `Evento ${this.state.debugInfoId}` || ''}
                                        iconStyle = 'triangle'
                                        collapsed = { 1 }
                                        displayDataTypes = { false }
                                        enableClipboard = { false }
                                    />}
                                    {this.state.debugInfoId !== null && (typeof this.state.debugInfo !== 'object') && <pre>{this.state.debugInfo}</pre>}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

export default withStyles(pageStyle)(RtuDebugDialog);